import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { roleTypes } from "Helpers/role-types.enum";
import { UserService } from "Services/UserService";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import dayjs from "dayjs";
import React, { useState } from "react";

type Action = {
  label: string;
  description: string;
  role: string;
  width: number;
  numTextFields?: number;
  textFieldTypes?: ("text" | "date")[]; // Specifies if the text field is for date or text input
  onClick: (textFieldValues?: (string | Date)[]) => void;
};

type Bookie = {
  name: string;
  actions: Action[];
};

const bookiesData: Bookie[] = [
  {
    name: "Unibet",
    actions: [
      {
        label: "Get Races",
        description: "Get Races from Unibet for today",
        role: roleTypes.ADMIN_PERMISSION,
        width: 150,
        onClick: async () => {
          await UserService.getUnibetRacesByDate(
            dayjs().format("YYYY-MM-DD"),
            dayjs().add(1, "day").format("YYYY-MM-DD"),
          );
        },
      },
    ],
  },
  {
    name: "Ladbrokes",
    actions: [
      {
        label: "Get Races",
        description: "Get Races from Ladbrokes for today",
        role: roleTypes.ADMIN_PERMISSION,
        width: 150,
        onClick: async () => {
          await UserService.getLadbrokesRacesByDate(
            dayjs().format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
          );
        },
      },
    ],
  },
  {
    name: "Extras",
    actions: [
      {
        label: "Update Pace",
        description: "Update Pace for horses in rides by date",
        role: roleTypes.ADMIN_PERMISSION,
        width: 150,
        numTextFields: 2,
        textFieldTypes: ["date", "date"],
        onClick: async (dates: any) => {
          const updatePace = await UserService.updateHorsePaceInRides(
            dates[0],
            dates[1],
          );
          if (updatePace?.status === 200) {
            return ShortSuccessToast({
              message:
                "Pace was updated from " +
                dayjs(dates[0]).format("YYYY/MM/DD") +
                " to " +
                dayjs(dates[1]).format("YYYY/MM/DD"),
            });
          }
          return ShortErrorToast({ message: "Pace failed to update" });
        },
      },
    ],
  },

  {
    name: "Not Recommended",
    actions: [
      {
        label: "not recommended",
        description: "select not recommended races by date",
        role: roleTypes.ADMIN_PERMISSION,
        width: 150,
        numTextFields: 2,
        textFieldTypes: ["date", "date"],
        onClick: async (dates: any) => {
          const updatePace = await UserService.NotRecomended(
            dates[0],
            dates[1],
          );
          if (updatePace?.status === 200) {
            return ShortSuccessToast({
              message:
                "not recommended was updated from " +
                dayjs(dates[0]).format("YYYY/MM/DD") +
                " to " +
                dayjs(dates[1]).format("YYYY/MM/DD"),
            });
          }
          return ShortErrorToast({ message: "not recommended failed to update" });
        },
      },
    ],
  },
];

const BookieCard = ({ name, actions }: { name: string; actions: Action[] }) => {
  const [textFieldValues, setTextFieldValues] = useState<{
    [actionIndex: number]: (string | Date)[];
  }>({});

  const handleTextFieldChange = (
    actionIndex: number,
    fieldIndex: number,
    value: string,
  ) => {
    setTextFieldValues((prevState) => ({
      ...prevState,
      [actionIndex]: {
        ...prevState[actionIndex],
        [fieldIndex]:
          actions[actionIndex].textFieldTypes?.[fieldIndex] === "date"
            ? new Date(value)
            : value,
      },
    }));
  };

  return (
    <Grid item xs={12} lg={12 / bookiesData.length}>
      <Card sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ textAlign: "center", pt: 3 }}>
          {name}
        </Typography>
        <CardContent sx={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            {actions.map((action, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography variant="body1">{action.description}</Typography>

                {Array.from({ length: action.numTextFields || 0 }).map(
                  (_, fieldIndex) => (
                    <TextField
                      key={fieldIndex}
                      type={action.textFieldTypes?.[fieldIndex] || "text"}
                      variant="outlined"
                      value={
                        action.textFieldTypes?.[fieldIndex] === "date" &&
                        textFieldValues[index]?.[fieldIndex]
                          ? dayjs(
                              textFieldValues[index][fieldIndex] as Date,
                            ).format("YYYY-MM-DD")
                          : (textFieldValues[index]?.[fieldIndex] as string) ||
                            ""
                      }
                      onChange={(e) =>
                        handleTextFieldChange(index, fieldIndex, e.target.value)
                      }
                      fullWidth
                    />
                  ),
                )}
                <DynamicButton
                  label={action.label}
                  requiredRoles={[action.role]}
                  width={action.width}
                  onClick={() => action.onClick(textFieldValues[index] || [])}
                />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

function Bookies() {
  return (
    <>
      <Typography variant="h4">Controller - Bookies</Typography>
      <Grid container spacing={2} mt={3} flexWrap="wrap">
        {bookiesData.map((bookie) => (
          <BookieCard
            key={bookie.name}
            name={bookie.name}
            actions={bookie.actions}
          />
        ))}
      </Grid>
    </>
  );
}

export default Bookies;
